import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { DateTime } from '../../../elements/DateTime';
import AccountDetails from '../../account/AccountDetails';
import { useCustomer } from '../../wrappers/WithCustomer';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import {
    BankCardPaymentMethod,
    PaymentMethod,
    PaymentMethodType,
    SBPPaymentMethod,
} from '@electrifly/central-client-api';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import { useMemo } from 'react';
import { useCustomerCreatePayLinkDialog } from '../dialogs/CreatePayLinkDialog';
import { BillingList } from '../../billings/components/BillingList';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { AccountChangeBalanceDialogHelper } from '../../account/dialogs/AccountChangeBalanceDialog';
import { API } from '../../../core/api-client';
import { WithAccountLoader } from '../../wrappers/WithAccountLoader';
import { WithAccount, useAccount } from '../../wrappers/WithAccount';
import { CustomerBalanceRefundDialogHelper } from '../dialogs/BalanceRefundDialog';

function BaseInfoBlock() {
    const customer = useCustomer();

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Информация</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Оператор" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={customer.operator && <OperatorLink id={customer.operator} />} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Телефон" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={customer.phone} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Email" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={customer.email} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Дата регистрации" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={<DateTime time={customer.created} />} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function ActionsBlock() {
    const customer = useCustomer();
    const account = useAccount();
    const showCustomerCreatePayLinkDialog = useCustomerCreatePayLinkDialog(store => store.show);

    const payForDebt = async () => {
        const [error, res] = await API.customerPayForDebt(customer._id, {});
        if (error) {
            console.trace(error);
        }
    };

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText primary="Оформить возврат" secondary="Оформить автоматический возврат" />
                        <ListItemSecondaryAction>
                            <Button
                                color="warning"
                                fullWidth
                                variant="contained"
                                onClick={() => CustomerBalanceRefundDialogHelper.show(customer._id, account.balance)}
                            >
                                Возврат
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            primary="Провести попытку оплаты"
                            secondary="Провести попытку платежа по долгу по привязанному способу"
                        />
                        <ListItemSecondaryAction>
                            <Button color="warning" fullWidth variant="contained" onClick={() => payForDebt()}>
                                Оплатить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Создать счёт" secondary="Создать ссылку для пополнения баланса" />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => showCustomerCreatePayLinkDialog(customer._id)}
                            >
                                Создать платёж
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText
                            primary="Пополнение баланса"
                            secondary="Зарегистрировать пополнение баланса клиента (прямой перевод или другие способы)"
                        />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => AccountChangeBalanceDialogHelper.show(customer.account)}
                            >
                                Пополнить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Stack>
        </>
    );
}

function AccountBlock() {
    const account = useAccount();

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Аккаунт</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <AccountDetails account={account} />
        </>
    );
}

function BillingListBlock() {
    const customer = useCustomer();
    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Биллинг</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <BillingList filter={{ account: customer.account }} />
        </>
    );
}

interface PaymentMethodItemBlockProps {
    item: PaymentMethod;
    selected: boolean;
}
function PaymentMethodItemBlock({ item, selected }: PaymentMethodItemBlockProps) {
    switch (item.type) {
        case 'bank_card':
            return <BankCardPaymentMethodItem item={item as BankCardPaymentMethod} selected={selected} />;
        case 'sbp':
            return <SBPPaymentMethodItem item={item as SBPPaymentMethod} selected={selected} />;
        default:
    }

    return (
        <ListItem key={item._id} divider>
            <ListItemIcon>
                <PaymentsRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={`${item.type}`} secondary={'Неизвестный тип способа оплаты'} />
            {selected && (
                <ListItemSecondaryAction>
                    <Tooltip title={'Выбранный способ оплаты'}>
                        <RadioButtonCheckedRoundedIcon />
                    </Tooltip>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}

interface BankCardPaymentMethodItemProps {
    item: BankCardPaymentMethod;
    selected: boolean;
}
function BankCardPaymentMethodItem({ item, selected }: BankCardPaymentMethodItemProps) {
    return (
        <ListItem key={item._id} divider>
            <ListItemIcon>
                <PaymentsRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={`${item.data.cardType} •• ${item.data.last4}`} secondary={item.data.issuerName} />
            {selected && (
                <ListItemSecondaryAction>
                    <Tooltip title={'Выбранный способ оплаты'}>
                        <RadioButtonCheckedRoundedIcon />
                    </Tooltip>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}

interface SBPPaymentMethodItemProps {
    item: SBPPaymentMethod;
    selected: boolean;
}
function SBPPaymentMethodItem({ item, selected }: SBPPaymentMethodItemProps) {
    return (
        <ListItem key={item._id} divider>
            <ListItemIcon>
                <PaymentsRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={'Счёт СБП'} secondary={item.data?.issuerName} />
            {selected && (
                <ListItemSecondaryAction>
                    <Tooltip title={'Выбранный способ оплаты'}>
                        <RadioButtonCheckedRoundedIcon />
                    </Tooltip>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}

function PaymentMethodsBlock() {
    const customer = useCustomer();
    const account = useAccount();

    const emptyPaymentMethods = useMemo(() => account.paymentMethods.length === 0, [account.paymentMethods.length]);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Способы оплаты</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {emptyPaymentMethods && (
                    <ListItem>
                        <ListItemText primary={`Нет привязанных способов оплаты`} />
                    </ListItem>
                )}

                {!emptyPaymentMethods &&
                    account.paymentMethods.map(item => (
                        <PaymentMethodItemBlock
                            key={item._id}
                            item={item}
                            selected={item._id === account.selectedPaymentMethod}
                        />
                    ))}
            </List>
        </>
    );
}

export default function CustomerDetailsPage() {
    const customer = useCustomer();

    return (
        <WithAccountLoader id={customer.account}>
            {account => (
                <WithAccount account={account}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12}>
                            <BaseInfoBlock />
                            <ActionsBlock />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <AccountBlock />
                            <PaymentMethodsBlock />
                        </Grid>
                        <Grid item xs={12}>
                            <BillingListBlock />
                        </Grid>
                    </Grid>
                </WithAccount>
            )}
        </WithAccountLoader>
    );
}
