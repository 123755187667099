import { StartTransactionEventLog } from '@electrifly/central-client-api';
import FaceIcon from '@mui/icons-material/Face';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import { Box, colors, Divider, Grid, Stack, Typography } from '@mui/material';
import { useSeqNumberByConnector } from '../../../../../../hooks/chargePointHooks';
import { ChargePointLink } from '../../../../../charge-point/elements/ChargePointLink';
import { ConnectorTypeText } from '../../../../../connector/Standard';
import { OwnerLink } from '../../../../../links/OwnerLink';
import { TransactionLink } from '../../../../../transaction/elements/TransactionLink';
import { useChargePoint, WithChargePoint } from '../../../../../wrappers/WithChargePoint';
import { useConnector, WithConnector } from '../../../../../wrappers/WithConnector';
import { EventLogTypeText } from '../../../../elements/EventTypeText';
import { DataWrap } from '../../../DataWrap';
import { Indicator } from '../../../Indicator';
import { Timestamp } from '../EventRow';
import { OwnerIcon } from '../../../../../links/OwnerIcon';
import { ChargeLocationLink } from '../../../../../charge-location/elements/ChargeLocationLink';

function ConnectorInformation() {
    const chargePoint = useChargePoint();
    const connector = useConnector();
    const number = useSeqNumberByConnector(chargePoint, connector);

    return (
        <Stack
            direction={'row'}
            alignItems="center"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
            <Typography fontSize={'inherit'}>{number}</Typography>
            <Typography fontSize={'inherit'}>
                <ConnectorTypeText type={connector.standard} />
            </Typography>
        </Stack>
    );
}

interface EventRowProps {
    event: StartTransactionEventLog;
}
export function StartTransactionEvent({ event }: EventRowProps) {
    return (
        <>
            <Typography variant="overline">
                <Timestamp time={event.timestamp} />
            </Typography>
            <Indicator color={colors.blue['600']} />
            <Typography component={Grid} item variant="button" sx={{ fontWeight: 500 }}>
                <EventLogTypeText type={event.type} />
            </Typography>
            <DataWrap color={colors.grey[200]}>
                <TransactionLink humanId={event.data.transactionHumanId} />
            </DataWrap>

            <DataWrap color={colors.grey[200]} icon={<OwnerIcon type={event.data.ownerType} />}>
                <Typography fontSize={'inherit'}>
                    <OwnerLink type={event.data.ownerType} owner={event.data.owner} />
                </Typography>
            </DataWrap>
            <Typography>
                {event.data.chargePoint === event.data.chargeLocation ? (
                    'роуминговая локация'
                ) : (
                    <ChargePointLink id={event.data.chargePoint} />
                )}
            </Typography>
            <Typography>→</Typography>
            <DataWrap color={colors.grey[200]} icon={<SettingsInputSvideoIcon />}>
                <WithChargePoint id={event.data.chargePoint}>
                    <WithConnector id={event.data.connector}>
                        <ConnectorInformation />
                    </WithConnector>
                </WithChargePoint>
            </DataWrap>
        </>
    );
}
