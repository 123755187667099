import { Transaction } from '@electrifly/central-client-api';
import BigNumber from 'bignumber.js';
import produce from 'immer';
import _ from 'lodash';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';
import { safeJsonParse } from '../../../../misc/Parser';
import { ColumnKey } from './TableColumns';

export type Filter = {
    limit: number;
    search?: string;
    active?: boolean;
    chargePoint?: string;
    chargeLocation?: string;
    operator?: string;
    holder?: string;
    owner?: string;
    ownerAccount?: string;
    emspOperator?: string;
};

export type Options = Record<ColumnKey, boolean>;

type Data = {
    transactions: Transaction[];
    initialized: boolean;
    loading: boolean;
    canLoadMore: boolean;

    filter: Filter;
    options: Options;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    setFilter: (data: Partial<Filter>) => void;
    setOption: (data: Partial<Options>) => void;
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    search: '',
    limit: 100,
};

const DEFAULT_OPTIONS: Options = {
    humnaId: true,
    location: true,
    chargePoint: true,
    connector: true,
    startTime: true,
    endTime: true,
    duration: true,
    stopReason: true,
    energy: true,
    energyCounter: false,
    tokenType: true,
    cost: true,
    status: true,
};

const LOCALSTORAGE_ITEM = 'transaction.columns';

function getOptionsFromLocalStorage(): Partial<Options> {
    const data = localStorage.getItem(LOCALSTORAGE_ITEM);
    if (!data) {
        return {};
    }
    const parsed = safeJsonParse<Options>(data);
    return parsed || {};
}

function setOptionsToLocalStorage(options: Options) {
    localStorage.setItem(LOCALSTORAGE_ITEM, JSON.stringify(options));
}

function createDefaultData(): Data {
    return {
        transactions: [],
        initialized: false,
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
        options: { ...DEFAULT_OPTIONS, ...getOptionsFromLocalStorage() },
    };
}

function transactionPassFilter(transaction: Transaction, filter: Filter): boolean {
    let passed = true;
    if (filter.search) {
        passed = passed && new BigNumber(filter.search).isEqualTo(transaction.transactionId);
    }
    if (filter.active) {
        passed = passed && !transaction.completed;
    }
    if (filter.chargePoint) {
        passed = passed && filter.chargePoint === transaction.chargePoint;
    }
    if (filter.chargeLocation) {
        passed = passed && filter.chargeLocation === transaction.chargeLocation;
    }
    if (filter.owner) {
        passed = passed && filter.owner === transaction.owner;
    }
    if (filter.ownerAccount) {
        passed = passed && filter.ownerAccount === transaction.ownerAccount;
    }
    if (filter.operator) {
        passed = passed && filter.operator === transaction.operator;
    }
    if (filter.holder) {
        passed = passed && filter.holder === transaction.holder;
    }
    if (filter.emspOperator) {
        passed = passed && filter.emspOperator === transaction.emspOperator;
    }

    return passed;
}

const createStore = (filter?: Partial<Filter>, options?: Partial<Options>) => {
    const initialData = {
        ...createDefaultData(),
        filter: { ...createDefaultData().filter, ...filter },
        options: { ...createDefaultData().options, ...options },
    };

    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.TRANSACTION.on(updatedTransaction => {
            const { transactions, filter, initialized } = get();
            if (!initialized) {
                return;
            }
            const passed = transactionPassFilter(updatedTransaction, filter);
            if (!passed) {
                set(draft => {
                    const index = draft.transactions.findIndex(item => item._id === updatedTransaction._id);
                    if (index !== -1) {
                        draft.transactions.splice(index, 1);
                    }
                });

                return;
            }

            const index = transactions.findIndex(item => item._id === updatedTransaction._id);
            if (index === -1) {
                const youngest = _.maxBy(transactions, item => item.transactionId);
                if (!youngest) {
                    return set({ transactions: [...transactions, updatedTransaction] });
                }

                if (youngest.transactionId > updatedTransaction.transactionId) {
                    return set({ transactions: [...transactions, updatedTransaction] });
                } else {
                    return set({ transactions: [updatedTransaction, ...transactions] });
                }
            }

            set(
                produce<Data>(draft => {
                    draft.transactions[index] = updatedTransaction;
                }),
            );
        });

        function resetData() {
            set({ transactions: [] });
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({ loading: true });

            const { filter, transactions } = get();

            const skip = transactions.length;
            const [error, res] = await API.transactionList({
                skip,
                limit: filter.limit,
                search: filter.search,
                active: filter.active,
                chargePoint: filter.chargePoint,
                chargeLocation: filter.chargeLocation,
                holder: filter.holder,
                owner: filter.owner,
                ownerAccount: filter.ownerAccount,
                emspOperator: filter.emspOperator,
            });

            if (error) {
                console.error(error);
                set({ loading: false, initialized: true });
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                initialized: true,
                canLoadMore: canLoadMore,
                transactions: [...get().transactions, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,

            reset: () => set({ ...initialData }),

            setFilter: (data: Partial<Filter>) => {
                set(draft => {
                    draft.filter = { ...draft.filter, ...data };
                });

                debouncedLoadNextWithReset();
            },

            setOption(data: Partial<Options>) {
                set(draft => {
                    draft.options = { ...draft.options, ...data };
                });

                const { options } = get();
                setOptionsToLocalStorage(options);
            },

            loadNext: loadNext,
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const TransactionListPageService = {
    Provider,
    createStore,
    useStore,
};
